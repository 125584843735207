import { mapPxToStrings } from '@/theme/utils/common';

// ----------------------------------------------------------------------------------------------------
// --- Breakpoints ---

export const breakpoints = {
  // _: 0,
  xs: 0,
  sm: 576, // - Mobile
  md: 860, // - Smallest tablet
  lg: 1024, // - Largest tablet
  xl: 1450, // - Smallest desktop
  mx: 1920, // - Large desktop
};

export const breakpointsPx = mapPxToStrings(breakpoints);

const createBreakpointAliases = <T extends Record<string, Breakpoint>>(breakpoints: T) =>
  breakpoints;
/**
 * Breakpoints with device names
 */
export const screen = createBreakpointAliases({
  _: 'xs',
  tablet: 'md',
  desktop: 'lg',
  desktopLarge: 'xl',
});

export const isBreakpointAlias = (breakpoint: string): breakpoint is BreakpointAlias =>
  breakpoint in screen;

export const breakpointAliasKeys = Object.keys(screen) as BreakpointAlias[];
export const breakpointKeys = Object.keys(breakpoints) as Breakpoint[];

export const getBreakpointKey = (breakpoint: Breakpoint | BreakpointAlias): Breakpoint => {
  if (isBreakpointAlias(breakpoint)) return screen[breakpoint as BreakpointAlias];
  return breakpoint;
};

export const getBreakpointValue = (breakpoint: Breakpoint | BreakpointAlias) => {
  return breakpoints[getBreakpointKey(breakpoint)];
};

export const getBreakpointPx = (breakpoint: Breakpoint | BreakpointAlias) => {
  return breakpointsPx[getBreakpointKey(breakpoint)];
};

export type Breakpoint = keyof typeof breakpoints;
export type BreakpointAlias = keyof typeof screen;
