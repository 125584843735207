/* eslint-disable @typescript-eslint/no-explicit-any */
import {createThemeContract, createVar} from '@vanilla-extract/css'
import {merge} from 'lodash'

type RecursiveOmitKeysWhere<T extends Record<string, any>, U> = Exact<{
  [P in keyof Omit<T, KeysWhere<T, U>>]: T[P] extends object
    ? RecursiveOmitKeysWhere<T[P], U>
    : T[P]
}>

type RecursiveOmitNullable<T extends Record<string, any>> =
  RecursiveOmitKeysWhere<T, null | undefined>

const stripObjectNullable = <T extends Record<string, any>>(
  obj: T
): RecursiveOmitNullable<T> => {
  return Object.keys(obj).reduce((result, key) => {
    let value = obj[key]
    if (value === undefined || value === null) return result

    if (typeof value === 'object') value = stripObjectNullable(value)

    return {...result, [key]: value}
  }, {} as RecursiveOmitNullable<T>)
}

// type ThemeTokens = typeof createTheme extends (tokens: infer T) => any ? T : never;

type Primitive = string | boolean | number | null | undefined

type MapLeafNodes<Obj, LeafType> = {
  [Prop in keyof Obj]: Obj[Prop] extends Primitive
    ? LeafType
    : Obj[Prop] extends Record<string | number, any>
    ? MapLeafNodes<Obj[Prop], LeafType>
    : never
}

// export const assignVarsPartial = <
//   Contract extends ThemeTokens,
//   Vars extends RecursivePartial<MapLeafNodes<Contract, string>>
// >(
//   contract: Contract,
//   vars: Vars
// ) => {
//   return assignVars(vars, vars);
// };
// type NullableTokens = {
//   [key: string]: string | NullableTokens | null;
// };

type CSSVarFunction = ReturnType<typeof createVar>
type Contract = {
  [key: string]: CSSVarFunction | Contract
}

createThemeContract

///////////////////////////////

// type PartiallyAppliedVars<Theme extends Contract,
//   Vars extends RecursivePartial<MapLeafNodes<Theme, string>>> = {
//     [P in Extract<keyof Theme, KeysWhere<Vars, string>>]:
//   }

export const createThemeDefaults = <Theme extends Contract>(
  contract: Theme,
  vars: PartialVars<Theme>
) => stripObjectNullable(vars)

type PartialVars<Theme extends Contract> = RecursivePartial<
  MapLeafNodes<Theme, string>
>

export const assignPartialVars = <Theme extends Contract>(
  contract: Theme,
  vars: PartialVars<Theme>
): Record<CSSVarFunction, string> => {
  return Object.keys(contract).reduce((result, mapKey) => {
    const contractValue: Contract | CSSVarFunction = contract[mapKey]
    const value = mapKey in vars ? vars[mapKey] ?? null : null

    if (!contractValue || value === null) return result

    // Handle object
    if (typeof contractValue === 'object' && typeof value === 'object') {
      return {
        ...result,
        ...assignPartialVars(
          contractValue,
          value as PartialVars<typeof contractValue>
        ),
      }
    }

    // Handle strings
    if (typeof contractValue === 'string' && typeof value === 'string') {
      return {
        ...result,
        [contractValue]: value,
      }
    }

    return result
  }, {} as Record<string, string>)
}

export const mergeVars = (...vars: Record<CSSVarFunction, string>[]) => {
  return merge({}, ...vars)
}
