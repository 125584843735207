import { EntryTypeId } from './parsers/entries';

export const SITE = process.env.NEXT_PUBLIC_SITE || 'default';
export const SITE_URL = process.env.SITE_URL;

/** App in 'development' environment */
export const IS_DEV = process.env.NODE_ENV === 'development';

export const DISABLE_CACHE = IS_DEV && process.env.NEXT_PUBLIC_DISABLE_CACHE === 'true';

export const TIMEZONE = process.env.NEXT_PUBLIC_TIMEZONE ?? 'Australia/Melbourne';
export const LOCALE = process.env.NEXT_PUBLIC_LOCALE ?? 'en-AU';
export const I18N_DEFAULT_LOCALE = 'en';

export const REVALIDATION_TIMEOUT = 60;
export const DURATION_TIMEOUT = 60;

export const DISABLE_STATIC_PATH_LIMITS = !!process.env.DISABLE_STATIC_PATH_LIMITS;

export const DRAFT_MODE_ROUTE = '/api/draft/';
export const QUERY_FORWARD_ROUTE = '/api/pass/';

export const SEPARATOR = `•`;

export enum IMG_RATIO {
  SQUARE = 1,
  PORTRAIT = 1.42, // 3:2
  LANDSCAPE = 496 / 744, // 0.715,
  LANDSCAPE_TALL = 0.8, // ~4:3
  LANDSCAPE_WIDE = 0.54, // 16:9
  VIDEO = 0.5625, // 16:9
}

export const PAGE_HEADER_COLORS = {
  service: 'redLight',
  serviceIndex: 'redLight',
  overview: 'redLight',
  profileIndex: 'gumLight',
  profile: 'white',
  articleIndex: 'pinkLight',
  article: 'blueLight',
  insightIndex: 'blueLight',
  insight: 'blueLight',
  search: 'blueLight',
} as const;

export type PageHeaderKey = keyof typeof PAGE_HEADER_COLORS;
export type PageHeaderColor = (typeof PAGE_HEADER_COLORS)[PageHeaderKey];

export type ImgRatios = typeof IMG_RATIO;
export type ImgRatio = keyof ImgRatios;

// Entry type URI settings

export const ENTRY_TYPE_URI_SETTINGS = {
  insightIndex: 'what-we-think',
  serviceIndex: 'what-we-do',
} as const;

export const SEARCHABLE_ENTRY_TYPES = [
  'article',
  'articleIndex',
  'home',
  'insight',
  'insightIndex',
  'sector',
  'expertise',
  'serviceIndex',
  'profile',
  'profileIndex',
  'page',
];
