import {recipe, RuntimeFn} from '@vanilla-extract/recipes'
import {ComplexStyleRule} from '@vanilla-extract/css'

// ------------------------------------------------------------------------------------------------
// Derive/recreate these types as they're not directly exported

type RecipeStyleRule = ComplexStyleRule | string
type VariantDefinitions = Record<string, RecipeStyleRule>
type BooleanMap<T> = T extends 'true' | 'false' ? boolean : T

export type VariantGroups = Record<string, VariantDefinitions>

/** Retrieve the parameters for a {@link RecipeFn} with the given variants */
export type VariantSelection<Variants extends VariantGroups> = {
  [VariantGroup in keyof Variants]?: BooleanMap<keyof Variants[VariantGroup]>
}

interface CompoundVariant<Variants extends VariantGroups> {
  variants: VariantSelection<Variants>
  style: RecipeStyleRule
}

type PatternOptions<Variants extends VariantGroups> = {
  base?: RecipeStyleRule
  variants?: Variants
  defaultVariants?: VariantSelection<Variants>
  compoundVariants?: Array<CompoundVariant<Variants>>
}

// ------------------------------------------------------------------------------------------------

/** An easier to work with, more helpfully named version of {@link RuntimeFn} */
export type RecipeRuntimeFn<T extends VariantGroups = VariantGroups> =
  RuntimeFn<T>

/** Utility returns a string union of variant properties from a given {@link VariantGroups} */
export type VariantKeys<Variants extends VariantGroups> = keyof Variants

/**
 * A modified version of the standard Vanilla Extract utility which
 * can accept a {@link RecipeObj}, and avoids an `undefined` union
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RecipeVariants<T extends RecipeRuntimeFn | RecipeObj<any>> =
  T extends RecipeObj<infer Variants> | RuntimeFn<infer Variants>
    ? // Check to see recipe has variants
      string extends keyof Variants
      ? // if not, return no-type
        {
          /*  */
        }
      : // else return the props
        Exact<NonNullable<VariantSelection<Variants>>>
    : never

/** Utility returns a string union of variant properties from a given recipe {@link RuntimeFn} */
export type RecipeVariantKeys<T extends RecipeRuntimeFn> =
  keyof RecipeVariants<T>

/**
 * A standard recipe {@link RuntimeFn} with an additional
 * property `variantKeys`: array of available variant prop names, which
 * can be used to destructure component props and apply the recipe
 */
export type RecipeObj<Variants extends VariantGroups> = {
  fn: RuntimeFn<Variants>
  variantKeys: VariantKeys<Variants>[]
}

/**
 * Type Guard to distinguish a {@link RecipeObj} from a recipe runtime functions
 */
export const isRecipeObj = <Variants extends VariantGroups>(
  x: RecipeRuntimeFn<Variants> | RecipeObj<Variants>
): x is RecipeObj<Variants> => {
  return typeof x !== 'function' && 'variantKeys' in x
}

/**
 * A decorator for the Vanilla Extract {@link recipe} function,
 * which returns a {@link RecipeRuntimeFnWithVars}
 */
export const createRecipeObj /* : RecipeCreator */ = <
  Variants extends VariantGroups
>(
  options: PatternOptions<Variants>,
  debugId?: string
) => {
  const variantKeys = Object.keys(
    options.variants ?? {}
  ) as VariantKeys<Variants>[]
  const fn = recipe(options, debugId) // as RecipeRuntimeFnWithVars<Variants>;

  return {
    fn,
    variantKeys,
  } as RecipeObj<Variants>
}
